<template>
  <div class="container inside-wrapper" id="landing">

    <div class="row back-image back-blue banner-2" style="">
        <div class="col-md-7 col-heading">
            <div class="banner-text">
                <h3>5 Ways Your Legacy System Is Costing You Money</h3>
                <ul type="none" class="social-landing">
                    <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Crashes and downtime reduces work quality and customer satisfaction</span></li>
                    <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>The system is not flexible and cannot keep up with business growth</span></li>
                    <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Increased costs due to additional IT hours spent resolving issues hurting productivity</span></li>
                    <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Diminished security for users and your data as well as possible compliance risks</span></li>
                    <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Your application does not allow new functionality to be added or uses non-supported libraries</span></li>
                </ul>
                <p>
                    </p><h4 class="text-bold">
                        "Thanks to Ocean Ring Technologies, they rebuilt our 15 year old software and even created a mobile app for our users in both
                        the iOS and Android stores." - Matt, Munilogic Property Management
                    </h4>
                <p></p>
                <br>
                <p>
                    Stop losing money maintaining your outdated system, fill out the form above and one of our representatives will contact
                    you at your convenience.
                </p>
            </div>

        </div>

        <div class="col-md-4 ">
            <div class="back-white contactcontent contact-form-landing">
                <div class="row">
                    <div class="col-sm-12">
                        <a id="consultation"></a>
                        <h2 class="heading wow fadeInDown animated text-blue">Learn How We Can Help</h2>
                    </div>
                </div>
                <div class="landing-contact">
                    <form id="contact-form" method="post" action="/Legacy/Contact" role="form">
                        <div class="messages"></div>
                        <div class="controls">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input id="form_name" type="text" name="name" class="form-control" required="" data-error="Name is required.">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <input id="form_phone" type="text" name="phone" class="form-control">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input id="form_email" type="email" name="email" class="form-control" required="required" data-error="Valid email is required.">
                                        <input id="form_location" type="text" name="location" class="form-control hide">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Company</label>
                                        <textarea id="form_message" name="message" class="form-control" rows="1" required="" data-error="Please, tell us the name of your organization."></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn-blue">Send </button>
                                </div>
                            </div>
                        </div>
                        <input name="__RequestVerificationToken" type="hidden" value="CfDJ8GhqxgZ9uGBPtIFHGDzrDOgfhYy_H3KEzHCQvmNt2wfJ8MdDKJZ3RgKfeOuk3-rDCv-h2W55B_29ewY0X8EhPoAwUiUwbHdW7IdzBnTvikWBU9rAnL1hDQaiVMKzFVMEB6489J99-oTixCkI92Q9Ixs">
                    </form>
                </div>
            </div>
        </div>

    </div>

    
            </div>
</template>

<script>
export default {
  name: "work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
